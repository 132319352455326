.device-modal-component {
  .modal-dialog {
    .modal-content {
      .modal-footer {
        background: var(--gray-light);
      }
      .modal-body {
        background: var(--gray-light);
        padding: 0;
        display: flex;

        .main {
          padding: 15px;
          flex: 3;

          .control-panel-secure-link-component,
          .control-panel-zone-component,
          .control-panel-form-component {
            border-radius: 8px;
            background: $white;
            margin: 10px 0;
            padding: 5px 0;

            > .row {
              margin: 0;
            }
          }

          .indicators {
            .list-group {
              border-radius: 8px;
              background: var(--gray-light);

              .list-group-item {
                background: transparent;
                text-align: center;
                text-transform: none;
                border: 0;
                font-weight: normal;
                font-size: 24px;
                line-height: 42px;
                position: relative;

                &.smaller {
                  font-size: 1rem;
                  line-height: 22px;
                  min-height: 52px;
                }

                &:after {
                  content: ' ';
                  border-bottom: 1px var(--gray-light) solid;
                  width: 60%;
                  bottom: 1px;
                  left: 20%;
                  position: absolute;
                }

                &:last-child {
                  &:after {
                    content: '';
                    display: none;
                  }
                }
              }
            }

            &.small {
              .list-group {
                .list-group-item {
                  padding: 5px 15px;
                  font-size: 16px;
                  line-height: 32px;
                  min-height: 42px;
                }
              }
            }

            &.white {
              .list-group {
                background: $white;
              }
            }
          }
        }

        .sidebar {
          padding: 15px;
          flex: 1;
          background: var(--gray-light);
        }
      }
    }
  }
}
