.device-table-component {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  .title {
    font-size: 20px;
  }

  .device-table-grid-container {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    position: relative;
    z-index: 9;
  }

  .header {
    position: relative;
    z-index: 10;

    .btn {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .form-control {
      height: 36px;
    }
  }

  .tag-list-component {
    cursor: default;
    .label {
      margin-right: 5px;
      background: #dddddd;
      color: $black;
      font-size: 12px;
    }
  }

  .btn {
    &.btn-link {
      text-decoration: underline;
    }

    &.btn-error {
      text-decoration: underline;
    }
  }

  .device-count {
    padding: 10px;
  }

  .table-wrapper .btn {
    padding: 0;
  }

  .column-select-button,
  .print-button {
    margin-left: 16px;
  }
}
