@media screen {
  .print-only {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
  * {
    font-weight: normal !important;
    text-decoration: none !important;
  }
  table {
    border-collapse: collapse;
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  div {
    page-break-inside: auto;
  }
  th,
  td {
    border: 1pt solid gray !important;
    text-align: center;
    word-wrap: break-word;
    * {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:after {
      content: '';
      display: inline-block;
      vertical-align: top;
      min-height: 1em;
    }
  }
  a {
    text-decoration: none;
    color: black !important;
  }
  a[href]:after {
    content: none !important;
  }

  .gm-style .gmnoprint,
  .gmnoprint {
    display: none;
  }
}
