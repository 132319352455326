.closeIcon {
  margin-left: 8px;
  margin-right: 4px;
  vertical-align: text-bottom;
}

.label {
  vertical-align: middle;
}

.closeIcon:hover {
  color: var(--red);
}
