@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import '~bootstrap/scss/bootstrap';
@import 'variables';

:root {
  --body-color: #333;
  --orange: #ff6c09;
  --gray-light: #f2efef;
  --movement-status-riding: #1aab2e;
  --movement-status-parked: #ffbb28;
  --movement-status-unknown: #b4b4b4;
  --cluster-status-riding: #1aab2e;
  --cluster-status-parked: #e9c100;
  --cluster-status-unknown: #a3a3a3;
}

// high level defines
html {
  min-height: 100vh;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--body-color);
  background: #fff;
  min-height: 100vh;
  margin: 0;
}

.form-label {
  color: var(--gray);
}

.custom-switch .custom-control-label {
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-flow: column nowrap;
}

.flex-justify-end {
  display: flex;
  justify-content: end;
}

.flex-align-center {
  align-items: center;
}

.app {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
}

.btn {
  white-space: nowrap;
}

// Custom components

.tz-selector {
  z-index: 10;

  ul li {
    background-color: #fff;
  }
}

.custom-table {
  thead th {
    background-color: #5a5a5a;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    font-weight: 600;
  }
  tbody td {
    vertical-align: middle;
    font-size: 12px;
  }
}

.validation-error {
  color: var(--danger);
}

// Modals

.modal-header {
  background-color: var(--orange);
  color: #fff;

  .close {
    color: #fff;
    opacity: 1;
  }
}

@import 'components/common/common';
@import 'components/common/map/_map';
@import 'components/device/device-view';
@import 'components/grid/device-modal';
@import 'components/grid/device-table';
@import 'components/common/grid/column-select/column-select';
@import 'components/landing/landing';
@import 'components/landing/login/_login';
@import 'components/landing/reset-password/_reset-password';
