.root {
  position: relative;
  height: 64px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 8px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.logo {
  width: 120px;
  margin-left: 50px;
}
