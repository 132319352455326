.column-select-modal-component {
  .column-draging {
    opacity: 0;
  }

  .column-sort {
    cursor: move;
  }

  .column-remove,
  .column-add {
    cursor: pointer;
  }
}
