.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 4px;
}

.subtitle {
  color: #888;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 4px;
}

.container {
  margin: 0 auto;
  margin-top: 8px;
  font-weight: 600;
  font-size: 12px;
}

.noDataLabel {
  height: 200px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
