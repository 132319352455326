.device-view-component {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding-bottom: 15px;

  .title {
    font-size: 20px;
    margin: 0;
  }

  .device-view-pagination {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    margin: 10px 0 0 0;
  }

  .device-view-pagination-btntoolbar {
    flex: 0 0 auto;
  }

  .device-view-pagination-info {
    flex: 1 1 auto;
  }

  .table-wrapper .btn {
    padding: 0;
  }

  .details {
    padding-left: 30px;
    padding-right: 30px;

    .row {
      padding-top: 30px;
      padding-bottom: 30px;
      background: $white;
      border-bottom: 10px var(--gray-light) solid;
    }

    .detail {
      .title {
        font-size: 16px;
        font-weight: bold;
        display: block;
        color: #9b9b9b;
        position: relative;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;

        &:after {
          width: 100px;
          content: ' ';
          bottom: -10px;
          border-bottom: 1px var(--gray-light) solid;
          margin-left: -50px;
          position: absolute;
          left: 50%;
        }
      }

      .value {
        text-align: center;
        display: block;
        color: $black;
        line-height: 34px;
        font-size: 29px;
        margin-bottom: 5px;
        font-weight: bold;

        &.small {
          font-size: 16px;
          color: #373a3c;
        }
      }
    }
  }

  .title {
    i {
      margin-left: 15px;
      color: var(--gray-dark) 2;
    }
  }

  .new-data-alert-component {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
  }
}
