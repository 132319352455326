@import '../../variables';

%cell {
  padding: 2px 10px;
  border-right: 1px solid var(--gray);
}

%body-cell {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  border-color: transparent;
}

.table-wrapper {
  flex: 1 1 auto;
  overflow: auto;
  position: relative;

  .table-scroller {
    position: absolute;
    z-index: 10;
  }

  .table-header {
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #5a5a5a;
    color: #fff;

    .table-header-cell {
      @extend %cell;
      display: flex;
      flex-flow: column nowrap;
      left: 0;
      justify-content: center;
      flex: 0 0 auto;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 12px;
      text-align: left;
      transition: background-color 0.15s ease-in-out;
      cursor: default;
    }

    .table-col-sortable {
      cursor: pointer;
    }

    .table-col-sortable:hover {
      background-color: #6a6a6a;
    }

    .table-header-cell-locked {
      z-index: 10;
      background-color: #5a5a5a;
    }
  }

  .table-main {
    background: $white;
    border-right: 1px solid #f0f0f0;

    .table-row:nth-child(odd) > div {
      background-color: var(--gray-light);
    }

    .table-row:nth-child(even) > div {
      background-color: #fff;
    }
  }

  .table-row {
    display: flex;
    flex-flow: row nowrap;

    > div {
      @extend %cell;
      @extend %body-cell;
      flex: 0 1 auto;
    }

    &.row-edit-mode > div {
      overflow: visible;
    }
  }

  .table-col-label {
    margin-right: 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .table-col-fixed {
    position: sticky;
    left: 0;
    z-index: 15;

    > div {
      @extend %cell;
      @extend %body-cell;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      box-sizing: border-box;
    }

    > div:nth-child(odd) {
      background-color: var(--gray-light);
    }

    > div:nth-child(even) {
      background-color: #fff;
    }
  }

  .table-col-resizer {
    width: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: e-resize;
  }

  .table-col-sort-dir {
    margin-left: 1em;
  }
}

.plain-table {
  width: 100%;
  background: $white;
  border: 1px solid #ddd;

  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }

  th {
    background-color: #f9f9f9;
  }

  th,
  td {
    padding: 2px 10px;
    border: 1px solid #f0f0f0;
    color: var(--gray-dark);
  }
}
