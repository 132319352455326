.root {
  display: flex;
  flex-flow: row nowrap;
}

.circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 4px;
  vertical-align: middle;
}

.label {
  vertical-align: middle;
}

.green {
  background-color: rgba(26, 171, 46, 1);
}
.blue {
  background-color: rgba(0, 138, 251, 1);
}
.yellow {
  background-color: rgba(255, 187, 40, 1);
}
.gray {
  background-color: rgba(180, 180, 180, 1);
}
.red {
  background-color: rgba(227, 0, 0, 1);
}
