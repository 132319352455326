.container {
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - 64px);
}

.sidebar {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.stats {
  transition: width 0.25s ease;
  width: 0;
  flex: 0 0 auto;
  background-color: #f2efef;
  overflow: hidden;
}

.stats:global(.open) {
  width: 380px;
}

.stats:global(.open):global(.expanded) {
  width: 760px;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow: auto;
  margin-top: 8px;
  padding: 16px;
}
