.map-device-id-overlay {
  position: absolute;
  background: white;
  color: #000;
  padding: 5px;
  white-space: pre;
  z-index: 1000;
  line-height: 200%;
}

.cluster img {
  width: 48px;
  height: 48px;
}

.map-controls {
  display: flex;
}

.map-control {
  align-self: center;
}

.deviceInfoBody span {
  font-weight: bold;
}

.map-dialog {
  padding: 2px 5px;
  margin-left: 15px;
  border: 1px solid #5f5f5f;
  background-color: #ffffff;
  box-shadow: 3px 3px 2px -2px #5f5f5f;

  p {
    font-size: 14px;
    margin: 0;
  }
}
