.root h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.value {
  margin-top: 8px;
  font-size: 42px;
  font-weight: 800;
  color: #008afb;
  text-align: center;
}

.arrow {
  color: #00c4a0;
}
