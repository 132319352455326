@import '../../variables';

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  &:active:not(.react-toggle--disabled) .react-toggle-thumb {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 5px 5px #0099e0;
  }
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all 0.2s ease;
}

.react-toggle--checked {
  .react-toggle-track {
    background-color: $color-primary;
  }
  .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  .react-toggle-track-x {
    opacity: 0;
  }
  .react-toggle-thumb {
    left: 27px;
    border-color: $color-primary;
  }
  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $color-primary;
  }
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0 0 2px 3px #0099e0;
}

.toggle-wrapper {
  display: flex;
  height: 100%;
  align-items: center;

  .toggle-label {
    display: inline-block;
    margin-right: 10px;
    line-height: 26px;
    font-size: 1rem;
    vertical-align: top;
  }

  .react-toggle {
    margin-right: 10px;
  }
}
