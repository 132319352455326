.root {
  width: 50px;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 48px;
}

.link {
  transition: background-color 0.1s ease;
  padding: 0 !important;
  text-align: center;
  font-size: 20px;
}

.link a {
  display: block;
  padding: 0.5rem 1rem;
}

.icon {
  transition: color 0.1s ease;
}

.link a:hover {
  background-color: rgba(255, 108, 9, 0.15);
}

.link:global(.active) .icon {
  color: var(--orange);
}

.icon {
  color: #888;
}
