.root {
  background-color: var(--white);
  padding: 8px;
  border: 1px solid var(--gray-light);
  border-radius: 4px;
  font-weight: normal;
  white-space: nowrap;
}

.name {
  font-weight: bold;
}

.footer {
  margin-top: 4px;
  line-height: normal;
  font-size: 0.8em;
}
