.root {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
}

.root:global(.expanded) .card {
  width: calc(50% - 32px);
  margin-right: 8px;
}

.top {
  flex: 0 0 auto;
}

.content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  flex: 1 1 auto;
  overflow: auto;
  padding: 8px;
}

.infoCharts {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

.card {
  min-height: 200px;
  min-width: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 1 auto;
  background: #fff;
  border-radius: 4px;
  margin: 8px;
  padding: 12px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--orange);
  color: #fff;
  padding: 12px 16px;
  margin-bottom: 0;
}
