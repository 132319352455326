#landing {
  height: 100vh;

  .logo {
    margin: 0 auto;
    width: 256px;
  }

  h1 {
    color: $black;
    text-transform: uppercase;
    font-size: 18px;
    font-style: italic;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 20px;
    color: var(--orange);
    font-weight: bold;
    margin-bottom: 16px;
  }
}
